const {
  VueFinalModal: VueFinalModalComponent,
  $vfm,
  ModalsContainer,
  vfmPlugin,
} = VueFinalModal;

const app = Vue.createApp(App);
window.vueApp = app;

app.use(Popper);

app.use(vfmPlugin);
app.use(VueFinalModalComponent);
app.use(ModalsContainer);
app.use($vfm);

app.use(CKEditor);

// app.use(VueKatex, {
//   globalOptions: {},
// });

app.component('katex-span', {
  props: ['expr'],
  computed: {
    math() {
      return katex.renderToString(this.expr);
    },
  },
  render() {
    return Vue.h('span', { innerHTML: this.math });
  },
});

app.component('katex-div', {
  props: ['expr'],
  computed: {
    math() {
      return katex.renderToString(this.expr);
    },
  },
  render() {
    return Vue.h('div', { innerHTML: this.math });
  },
});

app.config.globalProperties.$log = console.log;

app.mount(`#app`);
